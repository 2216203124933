body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.registerLink {
  color: #4050b5 !important;
  font-weight: bold !important;
}
.registerLink:hover, .registerLink:focus {
  background: none !important;
}

.seo 
{
    background-color:#ef5350;
    border-radius: 20px;
    padding: 2px 5px;
    margin-right: 3px;
    
}

.seo.contains-seo
{
    background-color:  #51b7ae;
}

.class1 thead tr th, .class1 tbody tr td  {
  border-style:none;
}

.class2 {
  margin: auto !important;
}

.class3 {
  width: 80% !important;
}

.class4 {
  width: 60% !important;
}

.class5 {
  width: 40% !important;
}

#discountValue {
  margin-right: 10px;
}